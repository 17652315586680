<template>
  <v-row>
    <div>
      <ServerError v-if="ServerError" />
      <vue-element-loading
        :active="appLoading"
        spinner="bar-fade-scale"
        color="#1976d2"
        size="128"
        is-full-screen
      />
    </div>
    <v-snackbar v-model="showSnackBar" color="#000" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: #fff">
            {{ msg }}
          </span>
        </v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: #fff">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <!-- <v-col cols="12" lg="12">
        <v-card no-header>
          <template slot="header">
            <v-row>
              <v-col cols="12" md="3" class="py-2">
                <span>Purchase Details</span>
              </v-col>
            </v-row>
          </template> -->
    <v-layout v-if="ServerError" wrap justify-center>
      <v-flex px-5 pt-5 xs12 sm12 md12 lg12 xl12 text-center>
        <ServerError />
      </v-flex>
    </v-layout>
    <v-layout v-else wrap>
      <v-flex px-5 pt-5 py-5 xs12 sm12 md12 lg12 xl12>
        <v-card>
          <v-card-title> Purchase Details </v-card-title>
          <div class="pa-6" v-if="purchase">
            <v-row>
              <v-col>
                <span style="color: black; font-family: poppinssemibold"
                  >Customer Name</span
                ><br />
                <span style="color: black; font-family: poppinsmedium">{{
                  purchase.name
                }}</span>
              </v-col>
              <v-col>
                <span style="color: black; font-family: poppinssemibold"
                  >Order Date</span
                ><br />
                <span style="color: black; font-family: poppinsmedium">{{
                  formatDate(purchase.init_date)
                }}</span>
              </v-col>
              <v-col>
                <span style="color: black; font-family: poppinssemibold"
                  >Phone</span
                ><br />
                <span
                  style="color: black; font-family: poppinsmedium"
                  v-if="purchase.mobile"
                  >{{ purchase.mobile }}</span
                >
                <span v-else style="color: black; font-family: poppinsmedium"
                  >-</span
                >
              </v-col>
              <v-col>
                <span style="color: black; font-family: poppinssemibold"
                  >Email</span
                ><br />
                <span
                  style="color: black; font-family: poppinsmedium"
                  v-if="purchase.email"
                  >{{ purchase.email }}</span
                >
                <span v-else style="color: black; font-family: poppinsmedium"
                  >-</span
                >
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <span style="color: black; font-family: poppinssemibold"
                  >Discount</span
                ><br />
                <span
                  style="color: black; font-family: poppinsmedium"
                  v-if="purchase.discount"
                  >{{ purchase.discount }}</span
                >
                <span v-else style="color: black; font-family: poppinsmedium"
                  >-</span
                >
              </v-col>
              <v-col>
                <span style="color: black; font-family: poppinssemibold"
                  >Total Amount</span
                ><br />
                <span
                  style="color: black; font-family: poppinsmedium"
                  v-if="purchase.amount"
                  >{{ purchase.amount }}</span
                >
                <span v-else style="color: black; font-family: poppinsmedium"
                  >-</span
                >
              </v-col>
              <v-col>
                <span style="color: black; font-family: poppinssemibold"
                  >Payment Status</span
                ><br />
                <span
                  style="color: black; font-family: poppinsmedium"
                  v-if="purchase.payment_status"
                  >{{ purchase.payment_status }}</span
                >
                <span v-else style="color: black; font-family: poppinsmedium"
                  >-</span
                >
              </v-col>
              <v-col>
                <span style="color: black; font-family: poppinssemibold"
                  >Transaction ID</span
                ><br />
                <span
                  style="color: black; font-family: poppinsmedium"
                  v-if="purchase.tsnId"
                  >{{ purchase.tsnId }}</span
                >
                <span v-else style="color: black; font-family: poppinsmedium"
                  >-</span
                >
              </v-col>
            </v-row>
          </div>
        </v-card>
        <div style="padding-bottom: 10px; font-family: poppinsmedium">
          <v-card>
            <v-card-title>Course Details</v-card-title>
            <div v-if="details">
              <v-data-table
                :headers="headers"
                :items="details"
                :search="search"
                :items-per-page="count"
                hide-default-footer
                class="elevation-1"
              >
                <!-- <template v-slot:[`item.Productname`]="{ item }">
                    <span v-if="item.product.productname">{{
                      item.product.productname
                    }}</span>
                  </template> -->
                <template v-slot:[`item.dealtype`]="{ item }">
                  <span v-if="item.courseId">
                    <v-img
                      contain
                      height="100px"
                      width="100px"
                      :src="mediaURL + item.courseId.frontImage"
                    >
                    </v-img>
                  </span>
                  <span v-else>-</span>
                </template>
                <template v-slot:[`item.Quantity`]="{ item }">
                  <span v-if="item.quantity">{{ item.quantity }}</span>
                  <span v-else>-</span>
                </template>
                <template v-slot:[`item.enroll`]="{ item }">
                  <span v-if="item.users.length > 0">
                    <v-layout wrap>
                      <v-flex xs12 sm6 v-for="(list, l) in item.users" :key="l">
                        <span>{{ list.name }},</span>
                      </v-flex>
                    </v-layout>
                  </span>
                  <span v-else>-</span>
                </template>
                <template v-slot:[`item.Price`]="{ item }">
                  <span v-if="item.price">{{ item.price }}</span>
                  <span v-else>-</span>
                </template>
              </v-data-table>
            </div>
            <div v-else>-</div>
          </v-card>
        </div>
      </v-flex>
    </v-layout>
  </v-row>
</template>
  
  <script>
import axios from "axios";
export default {
  data() {
    return {
      showSnackBar: false,
      msg: null,
      timeout: 5000,
      details: [],
      purchase: "",
      search: "",
      count: 10,
      appLoading: false,
      ServerError: false,
      headers: [
        { text: "Course Name", value: "courseName", sortable: false },
        { text: "Enroll Users", value: "enroll", sortable: false },
        { text: "Price", value: "price", sortable: false },
        { text: "Image", value: "dealtype", sortable: false },
        { text: "Quantity", value: "quantity", sortable: false },
        { text: "Total Price", value: "totalAmount", sortable: false },
      ],
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/admin/purchase/details",
        data: {
          id: this.$route.query.id,
        },
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.purchaseInfo.length > 0) {
            this.details = response.data.purchaseInfo;
          }
          this.purchase = response.data.purchase;
        })
        .catch((e) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(e);
        });
    },
    formatDate(item) {
      var dt = new Date(item);
      var day = dt.getDate();
      var year = dt.getFullYear();
      var hours = dt.getHours();
      var minutes = dt.getMinutes();
      dt = dt.toString();
      var ampm = hours >= 12 ? "pm" : "am";
      hours = hours % 12;
      hours = hours ? hours : 12;
      minutes = minutes < 10 ? "0" + minutes : minutes;
      var strTime =
        day +
        " " +
        dt.slice(4, 7) +
        " " +
        year +
        " " +
        hours +
        ":" +
        minutes +
        " " +
        ampm;
      return strTime;
    },
  },
};
</script>
  <style scoped>
.item_title {
  font-weight: bold;
  font-size: 14px;
}
.item_title1 {
  font-weight: bold;
  font-size: 16px;
}
.item_value {
  font-size: 13px;
}
.tdwidth {
  width: 50% !important;
}
</style>
  
import { render, staticRenderFns } from "./purchaseDetails.vue?vue&type=template&id=74c5f3ce&scoped=true&"
import script from "./purchaseDetails.vue?vue&type=script&lang=js&"
export * from "./purchaseDetails.vue?vue&type=script&lang=js&"
import style0 from "./purchaseDetails.vue?vue&type=style&index=0&id=74c5f3ce&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "74c5f3ce",
  null
  
)

export default component.exports